import React from "react";
import "../Stylesheets/ContactUs.css";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>
          We'd love to hear from you! Please fill out the form below and we'll
          get in touch with you as soon as possible.
        </p>
      </div>
      <h2>Fill the form below</h2>
      <div className="contact-form-container">
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Ex: Muhammad Asad Nazir"
              required
              className="input-contactus"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="EX: masad@digihut.pk"
              required
              className="input-contactus"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="EX: I want to inquire about, or i am looking for .."
              required
              className="input-contactus-textarea"
            ></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="contact-info">
        <h2 className="our-contect-info">Our Contact Information</h2>
        <div className="contact-info-child">
          <div className="contact-us-box">
            <img
              src="https://cdn-icons-png.flaticon.com/128/726/726623.png"
              alt=""
              className="contact-us-box-img"
            />
            <h5 className="call-to-action">Email Us at </h5>
            <h2>masadnazir1@gmail.com</h2>
          </div>
          <div className="contact-us-box">
            <img
              src="https://cdn-icons-png.flaticon.com/128/726/726623.png"
              alt=""
              className="contact-us-box-img"
            />
            <h5 className="call-to-action">Phone: </h5>
            <h2>03208648637</h2>
          </div>
          <div className="contact-us-box">
            <img
              src="https://cdn-icons-png.flaticon.com/128/726/726623.png"
              alt=""
              className="contact-us-box-img"
            />
            <h5 className="call-to-action">Address:</h5>
            <h2>Commitee Chowk RWP Pakistan</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
