import React from "react";
import "../Stylesheets/Projects.css";

const Projects = () => {
  const projects = [
    {
      title: "Project One",
      description: "A web application built using React and Node.js.",
      image: "https://via.placeholder.com/400x200",
    },
    {
      title: "Project Two",
      description:
        "A mobile app developed for iOS and Android using React Native.",
      image: "https://via.placeholder.com/400x200",
    },
    {
      title: "Project Three",
      description: "A machine learning project for predictive analytics.",
      image: "https://via.placeholder.com/400x200",
    },
  ];

  return (
    <div className="projects">
      <div className="projects-hero">
        <h1>Our Projects</h1>
        <p>
          Explore some of the projects we've worked on. Each project showcases
          our commitment to quality and innovation.
        </p>
      </div>
      <h1>Our Top Projects</h1>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <img src={project.image} alt={project.title} />
            <h2>{project.title}</h2>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
