import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/Slider.css";

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slide">
          <div className="slide-content">
            <h1>Innovative Tech Solutions</h1>
            <p>Leading the industry with cutting-edge software development</p>
            <div className="buttons">
              <button className="btn-primary">Learn More</button>
              <button className="btn-secondary">Contact Us</button>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="slide-content">
            <h1>Custom Software Development</h1>
            <p>Tailored solutions to meet your business needs</p>
            <div className="buttons">
              <button className="btn-primary">Our Services</button>
              <button className="btn-secondary">Get a Quote</button>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="slide-content">
            <h1>Expert Team</h1>
            <p>Our team of experts are here to support you</p>
            <div className="buttons">
              <button className="btn-primary">Meet the Team</button>
              <button className="btn-secondary">Join Us</button>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderComponent;
