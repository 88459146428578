import React from "react";
import "../Stylesheets/AboutUs.css";

const About = () => {
  return (
    <div className="about-us">
      <div className="about-hero">
        <h1>About Us</h1>
        <p>
          We are a leading software development company committed to delivering
          innovative solutions that drive business success.
        </p>
      </div>
      <div className="about-content">
        <section className="about-mission">
          <div className="left-abt-us">
            <img
              src="https://freepngimg.com/save/18793-vision-free-download-png/1121x1121"
              alt=""
              className="about-png-images"
            />
          </div>
          <div className="right-abt-us">
            <h2>Our Mission</h2>

            <p>
              Our mission is to provide exceptional software development
              services that enhance business operations and drive growth. We
              strive to deliver innovative, efficient, and scalable solutions
              tailored to our clients' unique needs.
            </p>
          </div>
        </section>

        <section className="about-mission">
          <div className="left-abt-us">
            <img
              src="https://freepngimg.com/save/18793-vision-free-download-png/1121x1121"
              alt=""
              className="about-png-images"
            />
          </div>
          <div className="right-abt-us">
            <h2>Our Vision</h2>

            <p>
              Our vision is to be a global leader in software development,
              recognized for our commitment to quality, innovation, and customer
              satisfaction. We aim to transform the tech industry through
              cutting-edge solutions and unparalleled service.
            </p>
          </div>
        </section>

        <section className="about-mission">
          <div className="left-abt-us">
            <img
              src="https://freepngimg.com/save/18793-vision-free-download-png/1121x1121"
              alt=""
              className="about-png-images"
            />
          </div>
          <div className="right-abt-us">
            <h2>Our Values</h2>
            <ul>
              <li>
                <strong>Innovation:</strong> Embracing new ideas and
                technologies to drive progress.
              </li>
              <li>
                <strong>Integrity:</strong> Upholding the highest standards of
                honesty and transparency.
              </li>
              <li>
                <strong>Excellence:</strong> Delivering superior quality in
                everything we do.
              </li>
              <li>
                <strong>Collaboration:</strong> Fostering teamwork and
                partnerships to achieve shared goals.
              </li>
              <li>
                <strong>Customer Focus:</strong> Putting our clients at the
                center of our business.
              </li>
            </ul>
          </div>
        </section>

        <section className="about-team">
          <h2>Our Team</h2>
          <div className="team-members">
            <div className="team-member">
              <div className="Team-picture">
                <img
                  src="https://scontent.fisb17-1.fna.fbcdn.net/v/t39.30808-1/452533382_1207418160288218_7125513773809313804_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=50d2ac&_nc_eui2=AeHaBnmZcYy4yp_RzORI9jkyu5N1d3RTiMW7k3V3dFOIxWbSr9QEyc-vTxCXLkSxEQS_aV_eFXrxRfCaChzd1_Dt&_nc_ohc=bN6Q7UyzysMQ7kNvgGlwpxZ&_nc_ht=scontent.fisb17-1.fna&gid=A-Lofucb1xHwBWvk6TkWACB&oh=00_AYBnexqs2g8_yifHZ6NxFt_XUvc60FXBKSVmxEK2kHSPYQ&oe=66B1A63B"
                  alt="Team Member"
                  className="Team-picture-img"
                />
              </div>
              <div className="Team-details">
                <h2 className="Team-mate-name">Muhammad Asad Nazir</h2>
                <p>CEO</p>
                <h4 className="team-detail-about">
                  Web Developer skilled in WordPress, Shopify, React.js, CSS,
                  and Node.js (Express API). Experienced in automating daily
                  tasks with Node.js and developing mobile apps with React
                  Native. Passionate about creating dynamic, user-friendly
                  websites and applications.
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
