import React from "react";
import "../Stylesheets/Home.css";
import SliderComponent from "../Components/Slider";
import HomeBody from "../Components/HomeBody";

const Home = () => {
  return (
    <main className="home">
      <section className="hero">
        {" "}
        <SliderComponent />
      </section>
      <HomeBody />
    </main>
  );
};

export default Home;
