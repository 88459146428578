import React from "react";
import "./HomeBody.css";
import project from "../Assets/home-blogbook.png";

const HomeBody = () => {
  return (
    <main className="home">
      <section className="about-us">
        <div className="container">
          <h2>About Us</h2>
          <p>
            We are a leading software development company, specializing in
            innovative solutions that drive business success. Our team of
            experts is dedicated to delivering high-quality software tailored to
            your unique needs.
          </p>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <h2>Our Services</h2>
          <div className="service-cards">
            <div className="service-card">
              <img
                src="https://codeplateau-website.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/02/04111108/app-banner.png"
                alt=""
                className="homebody-services-image"
              />
              <div className="homebody-services-details">
                <h3>Custom Software Development</h3>
                <p>
                  We provide custom software solutions designed to meet your
                  specific business requirements.
                </p>
                <button className="service-cards-btn">Show All services</button>
              </div>
            </div>
            <div className="service-card">
              <img
                src="https://codeplateau-website.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/02/04111108/app-banner.png"
                alt=""
                className="homebody-services-image"
              />
              <div className="homebody-services-details">
                <h3>Mobile App Development</h3>

                <p>
                  Our team develops user-friendly mobile applications for both
                  iOS and Android platforms.
                </p>
                <button className="service-cards-btn">Show All services</button>
              </div>
            </div>
            <div className="service-card">
              <img
                src="https://codeplateau-website.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/02/04111108/app-banner.png"
                alt=""
                className="homebody-services-image"
              />
              <div className="homebody-services-details">
                <h3>Web Development</h3>
                <p>
                  We create responsive and dynamic websites that enhance your
                  online presence.
                </p>
                <button className="service-cards-btn">Show All services</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio">
        <div className="container">
          <h2>Our Portfolio</h2>
          <div className="portfolio-items">
            <div className="portfolio-item">
              <img
                src={project}
                alt="Project 1"
                className="portfolio-item-img"
              />
              <h3>Project 1</h3>
              <p>A brief description of the project.</p>
            </div>
            <div className="portfolio-item">
              <img
                src={project}
                alt="Project 1"
                className="portfolio-item-img"
              />{" "}
              <h3>Project 2</h3>
              <p>A brief description of the project.</p>
            </div>
            <div className="portfolio-item">
              <img
                src={project}
                alt="Project 1"
                className="portfolio-item-img"
              />{" "}
              <h3>Project 3</h3>
              <p>A brief description of the project.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <div className="container">
          <h2>What Our Clients Say</h2>
          <div className="testimonial-cards">
            <div className="testimonial-card">
              <p>
                "The team at Tech Solutions did an outstanding job. Our project
                was completed on time and exceeded our expectations."
              </p>
              <h4>John Doe, CEO</h4>
              <img
                src="https://scontent.fisb17-1.fna.fbcdn.net/v/t39.30808-1/452533382_1207418160288218_7125513773809313804_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=50d2ac&_nc_eui2=AeHaBnmZcYy4yp_RzORI9jkyu5N1d3RTiMW7k3V3dFOIxWbSr9QEyc-vTxCXLkSxEQS_aV_eFXrxRfCaChzd1_Dt&_nc_ohc=bN6Q7UyzysMQ7kNvgGlwpxZ&_nc_ht=scontent.fisb17-1.fna&gid=A-Lofucb1xHwBWvk6TkWACB&oh=00_AYBnexqs2g8_yifHZ6NxFt_XUvc60FXBKSVmxEK2kHSPYQ&oe=66B1A63B"
                alt=""
                className="testimonials-image"
              />
            </div>
            <div className="testimonial-card">
              <p>
                "Excellent service and support. Highly recommend Tech Solutions
                for any software development needs."
              </p>
              <h4>Jane Smith, CTO</h4>
              <img
                src="https://scontent.fisb17-1.fna.fbcdn.net/v/t39.30808-1/452533382_1207418160288218_7125513773809313804_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=50d2ac&_nc_eui2=AeHaBnmZcYy4yp_RzORI9jkyu5N1d3RTiMW7k3V3dFOIxWbSr9QEyc-vTxCXLkSxEQS_aV_eFXrxRfCaChzd1_Dt&_nc_ohc=bN6Q7UyzysMQ7kNvgGlwpxZ&_nc_ht=scontent.fisb17-1.fna&gid=A-Lofucb1xHwBWvk6TkWACB&oh=00_AYBnexqs2g8_yifHZ6NxFt_XUvc60FXBKSVmxEK2kHSPYQ&oe=66B1A63B"
                alt=""
                className="testimonials-image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container">
          <h2>Ready to Get Started?</h2>
          <p>
            Contact us today to discuss your project and see how we can help you
            achieve your goals.
          </p>
          <button className="btn-primary">Contact Us</button>
          <button className="btn-secondary">Get a Quote</button>
        </div>
      </section>
    </main>
  );
};

export default HomeBody;
