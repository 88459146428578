import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Stylesheets/Header.css";
import hamburgerIcon from "../Assets/Menue.png";
import Logo from "../Assets/Logo.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  //
  //
  const closeMenu = () => {
    setMenuOpen(false);
  }; //

  return (
    <header className="header">
      <div className="">
        {" "}
        <img src={Logo} alt="Digihut.pk" className="Logo-Head"></img>
      </div>

      <nav className={`nav ${menuOpen ? "nav-open" : ""}`}>
        <ul className="nav-list">
          <li className="menue-item">
            <Link to="/" className="menue-item-a" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li className="menue-item">
            <Link to="/Projects" className="menue-item-a" onClick={closeMenu}>
              Projects
            </Link>
          </li>
          <li className="menue-item">
            <Link to="/Services" className="menue-item-a" onClick={closeMenu}>
              Services
            </Link>
          </li>
          <li className="menue-item">
            <Link to="/about" className="menue-item-a" onClick={closeMenu}>
              About
            </Link>
          </li>
          <li className="menue-item-contactus-btn">
            <Link
              to="/ContactUs"
              className="menue-item-a-btn"
              onClick={closeMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
      <div className="hamburger-menu" onClick={toggleMenu}>
        <img src={hamburgerIcon} alt="Menu" />
      </div>
    </header>
  );
};

export default Header;
