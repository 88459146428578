import React from "react";
import "../Stylesheets/Services.css";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const GoTO = useNavigate();

  const goToContactUs = () => {
    GoTO("/ContactUs");
  };

  const services = [
    {
      title: "Web Development",
      description:
        "Building responsive and scalable web applications using the latest technologies.",
      icon: "https://pngpix.com/images/hd/web-development-team-collaboration-illustration-yz5n469ruq1f8yb9.jpg",
    },
    {
      title: "Mobile App Development",
      description:
        "Creating user-friendly mobile applications for iOS and Android platforms.",
      icon: "https://pngpix.com/images/hd/web-development-team-collaboration-illustration-yz5n469ruq1f8yb9.jpg",
    },
    {
      title: "UI/UX Design",
      description:
        "Designing intuitive and engaging user interfaces and experiences.",
      icon: "https://pngpix.com/images/hd/web-development-team-collaboration-illustration-yz5n469ruq1f8yb9.jpg",
    },
    {
      title: "Cloud Solutions",
      description:
        "Providing scalable and secure cloud solutions to enhance business operations.",
      icon: "https://pngpix.com/images/hd/web-development-team-collaboration-illustration-yz5n469ruq1f8yb9.jpg",
    },
    {
      title: "AI & Machine Learning",
      description:
        "Implementing AI and machine learning solutions to drive innovation.",
      icon: "https://pngpix.com/images/hd/web-development-team-collaboration-illustration-yz5n469ruq1f8yb9.jpg",
    },
    {
      title: "Cybersecurity",
      description:
        "Ensuring the security and integrity of your digital assets and data.",
      icon: "https://pngpix.com/images/hd/web-development-team-collaboration-illustration-yz5n469ruq1f8yb9.jpg",
    },
  ];

  return (
    <div className="services">
      <div className="services-hero">
        <h1>Our Services</h1>
        <p>
          We offer a wide range of services to meet your software development
          needs. Our team of experts is dedicated to delivering high-quality
          solutions that drive business success.
        </p>
      </div>
      <h1>Our Top Selling Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">
              <img
                src={service.icon}
                alt={service.title}
                className="service-image"
              />
            </div>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
            <button className="Service-contact-btn" onClick={goToContactUs}>
              Contact Us
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
