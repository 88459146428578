import React from "react";
import "../Stylesheets/Footer.css";
import Logo from "../Assets/Logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <img src={Logo} alt="digihut.pk" className="footer-logo"></img>
          <h2>About Us</h2>
          <p>
            We are a leading software development company providing top-notch
            solutions for businesses worldwide. Our expertise spans web
            development, mobile applications, and enterprise solutions.
          </p>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/portfolio">Portfolio</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Contact Us</h2>
          <p>Email: info@techcompany.com</p>
          <p>Phone: +1 234 567 890</p>
          <p>Address: 123 Tech Street, Tech City, TX 12345</p>
        </div>
        <div className="footer-section social">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="https://facebook.com" className="facebook">
              <img
                src="https://cdn-icons-png.flaticon.com/128/15047/15047435.png"
                alt=""
                className="socialicons"
              />
            </a>
            <a href="https://twitter.com" className="twitter">
              <img
                src="https://cdn-icons-png.flaticon.com/128/15047/15047435.png"
                alt=""
                className="socialicons"
              />
            </a>
            <a href="https://linkedin.com" className="linkedin">
              <img
                src="https://cdn-icons-png.flaticon.com/128/15047/15047435.png"
                alt=""
                className="socialicons"
              />
            </a>
            <a href="https://instagram.com" className="instagram">
              <img
                src="https://cdn-icons-png.flaticon.com/128/15047/15047435.png"
                alt=""
                className="socialicons"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Tech Company. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
